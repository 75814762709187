import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './HomeManagement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faArrowLeft, faCircle, faMagnifyingGlass, faFolderOpen, faShare, faComment, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../Services/AuthContext';
import Alert from '../Alert/Alert'; 

function HomeManagement() {
    const { user, setProject } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation(); 
    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [alert, setAlert] = useState({ message: '', type: '', show: false });
    const [showWarning, setShowWarning] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); 
    const [hoveredOption, setHoveredOption] = useState(null);

    const fetchProjects = useCallback(() => {
        const userId = user?.id;  
        const userRole = user?.role || 'client';  
        
        if (!userId || !userRole) {
            console.error("L'ID utilisateur ou le rôle est manquant.");
            return;
        }
    
        const apiUrl = `https://webprogress.logixmedia.ch/webprogress/src/WebProgress/Backend/Project.php?action=get_all_projects&user_id=${userId}&user_role=${userRole}`;
    
        fetch(apiUrl)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setProjects(data.projects);
                } else {
                    console.error('Error fetching projects:', data.message);
                }
            })
            .catch(error => {
                console.error('Error fetching projects:', error);
            });
    }, [user]);
    
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 950) {
                setShowWarning(true);
            } else {
                setShowWarning(false);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (location.state && location.state.successMessage) {
            setAlert({ message: location.state.successMessage, type: 'success', show: true });
            window.history.replaceState({}, document.title);
        }
    }, [location.state]);

    useEffect(() => {
        if (user && user.id && user.role) {
            fetchProjects();  
        }
    }, [user, fetchProjects]);

    const handleMouseEnter = (option) => {
        setHoveredOption(option);
    };
    
    const handleMouseLeave = () => {
        setHoveredOption(null);
    };

    const handleProjectClick = (project) => {
        setSelectedProject(project);
        setProject(project);
    };

    const handleBackClick = () => {
        setSelectedProject(null);
    };

    const handleViewProjectClick = (project) => {
        navigate(`/gestion_dossier`, { state: { folder_id: project.folder_id } });
    };    

    const handleViewUploadClick = (project) => {
        navigate(`/gestion_contenu`, { state: { folder_id: project.folder_id } }); 
    };

    const handleViewCreateClick = () => {
        navigate(`/gestion_creation_dossier`);
    };

    const handleViewChatClick = (project) => {
        if (project && project.id) {
            setProject(project);
            localStorage.setItem('activeProject', JSON.stringify(project));
            navigate(`/discussion/${project.id}`, { state: { folder_id: project.folder_id } });
        }
    };

    const handleDeleteProjectByFolderClick = (project) => {
        if (window.confirm(`Êtes-vous sûr de vouloir supprimer le projet ${project.name} ?`)) {
            fetch('https://webprogress.logixmedia.ch/webprogress/src/WebProgress/Backend/Project.php', { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    action: 'delete_project_by_folder',
                    folder_id: project.folder_id,
                    type: project.type
                }),
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur serveur : ' + response.statusText);
                }
                return response.json();
            })
            .then(data => {
                if (data.success) {
                    setAlert({ message: 'Projet supprimé avec succès.', type: 'success', show: true });
                    setSelectedProject(null);
                    fetchProjects();
                } else {
                    setAlert({ message: data.message, type: 'error', show: true });
                }
            })
            .catch(error => {
                console.error('Erreur lors de la suppression du projet:', error);
                setAlert({ message: 'Une erreur est survenue lors de la suppression du projet.', type: 'error', show: true });
            });
        }
    };

    const filteredProjects = projects.filter((project) =>
        project.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
        project.folder_id.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            {showWarning ? (
                <div className="no-screen">
                    <p>Votre appareil ou le format de l'écran n'est pas compatible avec cette application. Veuillez utiliser un appareil avec une résolution supérieure à 950px de largeur.</p>
                </div>
            ) : (
                <div className='wrapper-homemanagement'>
                    {alert.show && (
                        <Alert 
                            message={alert.message} 
                            type={alert.type} 
                            onClose={() => setAlert({ message: '', type: '', show: false })} 
                        />
                    )}
                    {!selectedProject && <h1>Gestion des projets</h1>}

                    {!selectedProject && ( 
                        <div className='container-research'>
                            <div className='research'>
                                <input 
                                    type="text" 
                                    placeholder="Rechercher" 
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)} 
                                />
                                <span><FontAwesomeIcon icon={faMagnifyingGlass} /></span>
                            </div>
                        </div>
                    )}

                    {!selectedProject ? (
                        <>
                            <div className='container-homemanagement'>
                                <div className='homemanagement-option' 
                                    onClick={handleViewCreateClick}
                                    onMouseEnter={() => handleMouseEnter('create')}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <p>Créer un nouveau projet</p>
                                    <button>
                                        <FontAwesomeIcon 
                                            icon={faChevronRight} 
                                            style={{
                                                transform: hoveredOption === 'create' ? 'scale(1.5)' : 'scale(1)', 
                                                transition: 'transform 0.3s ease'
                                            }} 
                                        />
                                    </button>
                                </div>

                                <hr />
                                {filteredProjects.length > 0 ? (
                                    filteredProjects.map((project, index) => (
                                    <div 
                                        className='homemanagement-option' 
                                        key={index} 
                                        onClick={() => handleProjectClick(project)}
                                        onMouseEnter={() => handleMouseEnter(`project-${index}`)}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <span className={project.type === 'website' ? 'circle blue' : 'circle green'}>
                                            <FontAwesomeIcon icon={faCircle} />
                                        </span>
                                        <p>{project.name}</p>
                                        <button>
                                            <FontAwesomeIcon 
                                                icon={faChevronRight} 
                                                style={{
                                                    transform: hoveredOption === `project-${index}` ? 'scale(1.5)' : 'scale(1)',
                                                    transition: 'transform 0.3s ease'
                                                }} 
                                            />
                                        </button>
                                    </div>
                                    
                                    ))
                                ) : (
                                    <div className='homemanagement-option' style={{ cursor: 'default' }}>
                                        <p>Aucun projet trouvé</p>
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <div>
                            <button className='back-button' onClick={handleBackClick}><FontAwesomeIcon icon={faArrowLeft} /></button>
                            <h2>{selectedProject.name}</h2>
                            <div className='container-homemanagement'>
                                <div 
                                    className='homemanagement-option' 
                                    onClick={() => handleViewProjectClick(selectedProject)}
                                    onMouseEnter={() => handleMouseEnter('viewProject')}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <div className='container-icon'>
                                        <FontAwesomeIcon className='icon' icon={faFolderOpen} />
                                    </div>
                                    <p>Visualiser le dossier</p>
                                    <button>
                                        <FontAwesomeIcon 
                                            icon={faChevronRight} 
                                            style={{
                                                transform: hoveredOption === 'viewProject' ? 'scale(1.5)' : 'scale(1)',
                                                transition: 'transform 0.3s ease'
                                            }} 
                                        />
                                    </button>
                                </div>

                                <div 
                                    className='homemanagement-option' 
                                    onClick={() => handleViewUploadClick(selectedProject)}
                                    onMouseEnter={() => handleMouseEnter('viewUpload')}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <div className='container-icon'>
                                        <FontAwesomeIcon className='icon' icon={faShare} />
                                    </div>
                                    <p>Contenu du dossier</p>
                                    <button>
                                        <FontAwesomeIcon 
                                            icon={faChevronRight} 
                                            style={{
                                                transform: hoveredOption === 'viewUpload' ? 'scale(1.5)' : 'scale(1)',
                                                transition: 'transform 0.3s ease'
                                            }} 
                                        />
                                    </button>
                                </div>

                                <div 
                                    className='homemanagement-option' 
                                    onClick={() => handleViewChatClick(selectedProject)}
                                    onMouseEnter={() => handleMouseEnter('viewChat')}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <div className='container-icon'>
                                        <FontAwesomeIcon className='icon' icon={faComment} />
                                    </div>
                                    <p>Discussion du projet</p>
                                    <button>
                                        <FontAwesomeIcon 
                                            icon={faChevronRight} 
                                            style={{
                                                transform: hoveredOption === 'viewChat' ? 'scale(1.5)' : 'scale(1)',
                                                transition: 'transform 0.3s ease'
                                            }} 
                                        />
                                    </button>
                                </div>

                                <div 
                                    className='homemanagement-option' 
                                    id="delete" 
                                    onClick={() => handleDeleteProjectByFolderClick(selectedProject)}
                                    onMouseEnter={() => handleMouseEnter('delete')}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <div className='container-icon'>
                                        <FontAwesomeIcon className='icon' style={{ color: '#ffffff' }} icon={faTrash} />
                                    </div>
                                    <p>Supprimer le projet</p>
                                    <button id='delete-button'>
                                        <FontAwesomeIcon 
                                            icon={faChevronRight} 
                                            style={{
                                                transform: hoveredOption === 'delete' ? 'scale(1.5)' : 'scale(1)',
                                                transition: 'transform 0.3s ease'
                                            }} 
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}

            <div className='container-info-homemanagement'>
                <p><span className='circle blue'><FontAwesomeIcon icon={faCircle} /></span> Projet Website</p>
                <p><span className='circle green'><FontAwesomeIcon icon={faCircle} /></span> Projet Digital Campaigns</p>
            </div>
        </>
    );
}

export default HomeManagement;
